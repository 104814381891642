enum AppPagesEnum {
    ARTICLE_PAGE = 'ARTICLE_PAGE',
    ASSIGN_ARTICLE = 'ASSIGN_ARTICLE',
    CHANGE_PRIMARY_EMAIL = 'CHANGE_PRIMARY_EMAIL',
    DASHBOARD = 'DASHBOARD',
    EMAIL_ALERTS = 'EMAIL_ALERTS',
    HOME = 'HOME',
    LOGIN = 'LOGIN',
    LICENSE_SIGNING = 'LICENSE_SIGNING',
    MISSING_ARTICLE_DETAILS = 'MISSING_ARTICLE_DETAILS',
    OPEN_ACCESS_CONFIRMATION = 'OPEN_ACCESS_CONFIRMATION',
    ORDER = 'ORDER',
    ORDERS = 'ORDERS',
    ORDERS_FOCUS_ON_ARTICLE = 'ORDERS_FOCUS_ON_ARTICLE',
    PROFILE = 'PROFILE',
    PUB_EDITOR_REVIEW = 'PUB_EDITOR_REVIEW',
    RECOVER_PASSWORD = 'RECOVER_PASSWORD',
    REGISTRATION = 'REGISTRATION',
    REGISTRATION_BY_INVITE = 'REGISTRATION_BY_INVITE',
    RESET_PASSWORD = 'RESET_PASSWORD',
    REVIEW_PDF = 'REVIEW_PDF',
    REQUEST_ONLINE_OPEN = 'REQUEST_ONLINE_OPEN',
    SEARCH = 'SEARCH',
    TERMS_OF_SERVICE = 'TERMS_OF_SERVICE',
    VIEW_ONLINE_OPEN = 'VIEW_ONLINE_OPEN',
    VERIFY_EMAIL = 'VERIFY_EMAIL',
    VERSION = 'VERSION',
}

export default AppPagesEnum;
