/* eslint-disable promise/prefer-await-to-then */
import axios from 'axios';
import has from 'lodash.has';
import merge from 'lodash.merge';
import lstorage from 'app/blocks/common/storage';
import { getLocalStaticFiles } from './static-utils';

const defaultStaticCtx = getLocalStaticFiles();

export const BASE_URL = '/static';
const CODES_URL = `${BASE_URL}/codes`;
const HTML_URL = `${BASE_URL}/html`;

const defaultStatic = {};
defaultStaticCtx.keys().forEach(v => {
    const url = v.replace('./', `${BASE_URL}/`);

    if (/\.(html|json)$/.test(url)) {
        defaultStatic[url] = defaultStaticCtx(v);
    }
});

function isEnabled(key) {
    return lstorage.get(key) === true;
}

function toggle(key) {
    lstorage.set(key, !isEnabled(key));
}

const STATIC_FILES = 'staticFiles';
const STATIC_CODES = 'staticCodes';

export const toggleFiles = toggle.bind(null, STATIC_FILES);
export const toggleCodes = toggle.bind(null, STATIC_CODES);

function shouldAnnotateWithFilename() {
    return isEnabled(STATIC_FILES);
}

function shouldAnnotateWithCode() {
    return isEnabled(STATIC_CODES);
}

function shouldAnnotateMode() {
    return {
        codes: shouldAnnotateWithCode(),
        files: shouldAnnotateWithFilename(),
    };
}

function dotcmsURL(url) {
    return url.substr(BASE_URL.length);
}

export function postProcessCodes(url, codesOrigin, codesDefault, annotateMode) {
    function recursiveUpdate(node, processor, nodePath = [], isParentNodeMarkedAsOverrided = false) {
        const result = {};

        Object.keys(node).forEach(key => {
            const value = node[key];

            if (value == null) {
                return;
            }

            // replace * in key
            let overridedKey = false;
            if (key && key[0] === '*') {
                overridedKey = true;

                // eslint-disable-next-line no-param-reassign
                key = key.substr(1);
            }

            if (result[key]) {
                // Duplication - both keys (with * and not) present
                console.error(`Duplication in code [${key}] at ${url}:${nodePath.join('.')}`);

                if (!overridedKey) {
                    // Skip if key not with *
                    return;
                }
            }

            const path = [...nodePath, key];

            // mark ALL sub-groups (and current) as overridable
            const isMarkedAsOverrided = overridedKey || isParentNodeMarkedAsOverrided;

            if (typeof value === 'object') {
                result[key] = recursiveUpdate(value, processor, path, isMarkedAsOverrided);
            } else {
                const v = processor(value, path.join('.'), isMarkedAsOverrided);
                if (v !== undefined) {
                    result[key] = v;
                }
            }
        });

        return result;
    }

    const cleanDefault = recursiveUpdate(codesDefault[url], v => v);
    const cleanOrigin = recursiveUpdate(codesOrigin, v => v);
    const override = recursiveUpdate(codesDefault[url], (value, path, overrided) => (overrided ? value : undefined));

    const codes = merge(cleanDefault, cleanOrigin, override);

    function annotate(value, path) {
        if (has(override, path)) {
            return value;
        }

        let result = value;

        if (annotateMode.codes) {
            if (has(cleanOrigin, path)) {
                result = path;
            } else {
                result = `(!)${path}`;
            }
        }

        if (annotateMode.files) {
            result = `${dotcmsURL(url)}: ${result}`;
        }

        return result;
    }

    return annotateMode.codes || annotateMode.files ? recursiveUpdate(codes, annotate) : codes;
}

const storage = {};

function getCodes(file: string): Promise<Record<string, any>> {
    const url = `${CODES_URL}/${file}`;

    if (!storage[url]) {
        if (process.env.NODE_ENV === 'test') {
            storage[url] = Promise.resolve(postProcessCodes(url, {}, defaultStatic, shouldAnnotateMode()));
        } else {
            storage[url] = axios
                .get(url)
                .then(response => postProcessCodes(url, response.data, defaultStatic, shouldAnnotateMode()))
                .catch(error => {
                    console.error(error);
                    return postProcessCodes(url, {}, defaultStatic, shouldAnnotateMode());
                });
        }
    }

    return storage[url];
}

function getHtml(resourceUrl: string): Promise<string> {
    const url = `${HTML_URL}/${resourceUrl}`;

    if (storage[url]) {
        return storage[url];
    }

    const forbiddenContentIds = [
        'content',
        'AS__menu',
        'notifications',
        'waitingDialog',
        'AS__footer',
        'AS__info',
        'as__info',
        'selenium-ajax-indicator',
    ];

    function postProcessHTML(html = '') {
        if (html.search(new RegExp(`id=['"](${forbiddenContentIds.join('|')})["']`)) >= 0) {
            return `<span class="test">Requested data blocked, please do not use html in templates with html ids like: ${forbiddenContentIds.join(
                ', ',
            )}</span>`;
        }

        if (!shouldAnnotateWithFilename()) {
            return html;
        }

        return `
                <div style="border: 1px dotted red">
                    <h6 style="color: red">Static Text File: ${dotcmsURL(url)}</h6>
                    ${html}
                </div>`;
    }

    if (process.env.NODE_ENV === 'test') {
        storage[url] = Promise.resolve(
            (() => {
                if (defaultStatic[url] != null) {
                    return postProcessHTML(defaultStatic[url]);
                }

                return `Requested data not found: ${url}`;
            })(),
        );
    } else {
        storage[url] = axios
            .get(url)
            .then(response => {
                if (response.data.includes('bffd2150-a03e-42e5-a531-50594dae57e3')) {
                    throw new Error(`Requested data not found: ${url}`);
                }

                return response.data;
            })
            .then(postProcessHTML)
            .catch(error => {
                console.error(error);

                if (defaultStatic[url] != null) {
                    return postProcessHTML(defaultStatic[url]);
                }

                return `Requested data not found: ${url}`;
            });
    }

    return storage[url];
}

export default {
    getCommonCodes: () => getCodes('legacy/common.codes.json'),
    getLoginCodes: () => getCodes('legacy/login.codes.json'),
    getProfileCodes: () => getCodes('profile.codes.json'),
    getProfileAccountCodes: () => getCodes('legacy/profile-account.codes.json'),
    getRecoverPasswordCodes: () => getCodes('legacy/recover-password.codes.json'),
    getRegistrationCodes: () => getCodes('legacy/registration.codes.json'),
    getResetPasswordCodes: () => getCodes('legacy/reset-password.codes.json'),
    getMenuCodes: () => getCodes('menu.codes.json'),
    getFunderCodes: () => getCodes('funder.codes.json'),
    getErrorCodes: () => getCodes('error.codes.json'),
    getFieldNames: () => getCodes('field.names.json'),
    getPanelCodes: () => getCodes('dashboard/panel_actions.json'),
    getPanelStatusCodes: () => getCodes('dashboard/panel_status.json'),
    getDashboardCodes: () => getCodes('dashboard/dashboard.json'),
    getDashboardLeftSideCodes: () => getCodes('dashboard/left_side.json'),
    getDashboardRightSideCodes: () => getCodes('dashboard/right_side.json'),
    getCommunicationHistoryCodes: () => getCodes('dashboard/communication_history.json'),
    getCommunicationHistoryItemCodes: () => getCodes('dashboard/communication_history__item.json'),
    getOrdersCodes: () => getCodes('dashboard/orders.json'),
    getFullLicenseCodes: () => getCodes('license.codes.json'),
    getOrderPanelsCodes: () => getCodes('order-panels.codes.json'),
    getButtonsCodes: () => getCodes('buttons.codes.json'),
    getPrompt: () => getCodes('prompt/prompt.json'),
    getTooltip: () => getCodes('tooltip/tooltip.json'),
    getLicenseCodes: () => getCodes('license__types.json'),
    getLicenseSelectionGridCodes: () => getCodes('license__types.selection-grid-config.codes.json'),
    getLicenseActionsCodes: () => getCodes('license__actions.codes.json'),
    getSearchCodes: () => getCodes('search.codes.json'),
    getMyProfileCodes: () => getCodes('my-profile.codes.json'),
    getArticleCodes: () => getCodes('article.codes.json'),
    getArticleAffiliationsEditorCodes: () => getCodes('article-affiliations-editor.codes.json'),
    getArticleAffiliationsHelpWidgetCodes: () => getCodes('article-affiliations-help-widget.codes.json'),
    getArticleFundersEditorCodes: () => getCodes('article-funders-editor.codes.json'),
    getInstitutionPickerCodes: () => getCodes('institution_picker.codes.json'),
    getInviteColleagueCodes: () => getCodes('invite-colleague.codes.json'),
    getSimplePanelCodes: () => getCodes('panel.codes.json'),
    getUploadLicenseCodes: () => getCodes('upload-license.codes.json'),
    getOrcidPopUpCodes: () => getCodes('orcid-popup.codes.json'),
    getOrcidAffiliationList: () => getCodes('profile/affiliation-list-from-orcid.codes.json'),
    getComboboxCodes: () => getCodes('combobox.codes.json'),
    getAddressDoctorCodes: () => getCodes('address-doctor.codes.json'),
    getDialogCodes: () => getCodes('dialog.codes.json'),
    getServiceNames: () => getCodes('service-names.json'),
    getDiscountWidgetCodes: () => getCodes('discount-widget.codes.json'),
    getLicenseSigningCodes: () => getCodes('license-signing.codes.json'),
    getSupportWidgetCodes: () => getCodes('support-widget.codes.json'),
    getDashboardWidgetCodes: () => getCodes('dashboard/widgets.json'),
    getEmailAlertsCodes: () => getCodes('email-alerts.json'),
    getLicenseSubmission: () => getCodes('license-submission.codes.json'),

    getHtml,
};
