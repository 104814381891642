import { REST, GET as DEFAULT_GET } from './utils';

const { GET } = REST('/dashboard');

export type Citation = {
    articleTitle: string;
    authors: Array<{ name: string; orcid?: string }>;
    doi: string;
    firstPage: string;
    issueNumber: string;
    journalTitle: string;
    volumeNumber: string;
    year: number;
};

type Article = {
    id: string;
    authors: Array<{ name: string; participantId: string }>;
    citations: {
        items: Citation[];
        total: number;
    };
    doi: string;
    funderStatement?: string;
    name: string;
};

type Journal = {
    id: string;
    abbreviation: string;
    coverImageUrl: string;
    doi: string;
    eissn: string;
    name: string;
    onlineOpen: boolean;
    openAccess: boolean;
    pissn: string;
    revenueModel: 'OA' | 'OO';
    prices: Price[];
};

export type RcaInfo = {
    rcaStatus: RcaStatus;
    isEeoCaMe: boolean;
    eeoCaFullName: string;
};

export type GeoWaiver = {
    showGeoWaiverBanner: boolean;
    waiverCountry: string;
};

export interface ArticlePayload {
    article: Article;
    eligibility?: {
        message: string;
        isInstitutionalDiscount?: boolean;
        otherFunders?: string[];
    };
    flow: {
        OOOrder?: Record<string, any>;
    };
    history: Array<{ status: string; date: string }>;
    journal: Journal;
    options: {
        makeVideoAbstract?: boolean;
        coverCharge?: boolean;
        shareArticle?: boolean;
    };
    participantRole: ParticipantRole;
    publication: {
        statusCode: string;
        statusDate: string;
        available: boolean;
        pdfAvailable: boolean;
    };
    rcaInfo: RcaInfo;
    geoWaiver?: GeoWaiver;
    requiredActions: boolean;
    status: {
        published: boolean;
        withdrawn: boolean;
        onlineOpen: boolean;
        merged: boolean;
    };
}

export const getDashboard = (): Promise<{ payload: ArticlePayload[] }> => GET(undefined, undefined, { detailed: true });

export const getArticle = (articleId: string): Promise<{ payload: ArticlePayload }> =>
    GET(`/${articleId}`, undefined, { detailed: true });

export const getShareUrl = (doi: string): Promise<{ shareUrl: string; literatumUrl: boolean }> =>
    DEFAULT_GET(`/article-share/${doi}`);
