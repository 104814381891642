import routesJson from 'app/pages/routes.json';
import tempStorage from 'app/route/tempStorage';

let history;

function setHistory(value: History) {
    history = value;
}

function isOnlyMenu(): boolean {
    return !history;
}

function convertToHashUrl(url: string): string {
    return `${routesJson.root}#${url.replace(/^\//, '')}`;
}

function navigate(
    url: string,
    params: { replace: boolean } = { replace: false },
    paramsForNextRoute: Record<string, any> = undefined,
) {
    if (isOnlyMenu()) {
        window.location.href = convertToHashUrl(url);
        return;
    }

    tempStorage.set(paramsForNextRoute);

    if (params.replace) {
        history.replace(url[0] !== '/' ? `/${url}` : url);
    } else {
        history.push(url[0] !== '/' ? `/${url}` : url);
    }
}

function reload() {
    document.location.reload();
}

function isHere(location: Location, url: string) {
    return location.href.replace(location.origin, '').startsWith(convertToHashUrl(url));
}

function goBack() {
    history.back();
}

export { convertToHashUrl, isHere, navigate, reload, setHistory, goBack };
