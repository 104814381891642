import { keymirror } from 'app/blocks/common/utils';

const Events = keymirror({
    AFFILIATIONS_IMPORTED: '',
    AFFILIATIONS_UPDATED: '',
    AVATAR_IMAGE_CHANGED: '',
    EMAILS_IMPORTED: '',
    OPEN_ORCID_MODAL: '',
    ORCID_ALREADY_EXISTS: '',
    ORCID_IMPORTED: '',
    PAGE_CHANGED: '',
    PROFILE_DATA_FILLED: '',
    PROFILE_INFORMATION_IMPORTED: '',
    PROFILE_INFORMATION_SAVED: '',
    USER_CHANGED: '',
});

export default Events;
