import versions from 'app/blocks/ajax/config/versions';

function isMock(): boolean {
    return versions.ENV === 'kuber-mock' || versions.ENV === 'local';
}

function isNonProd(): boolean {
    return versions.ENV !== 'aws-wprod';
}

export { isMock, isNonProd };
