import _ from 'underscore';
import { getUser } from 'app/blocks/middleware/authentication';
import { confirm } from 'app/blocks/Modal/utils';

const LEAVE_PAGE_MESSAGE = 'You may have unsaved data, do you want to leave this page?';

type Hook = {
    askUserForAction: (fn: (allowNavigation: boolean) => void) => void;
    checkUnsavedChanges: () => boolean;
    historyFragment?: string;
};

let hook: Hook = null;
let isListenersAdded: boolean = false;

function removeNavigationListener() {
    hook = null;

    if (isListenersAdded) {
        // eslint-disable-next-line @typescript-eslint/no-use-before-define
        window.removeEventListener('beforeunload', onBeforeUnload);
        isListenersAdded = false;
    }
}

function getHistoryFragment(): string {
    const { hash } = window.location;
    return hash ? hash.substr(1) : '';
}

function checkChanges(): boolean {
    if (!hook) {
        return false;
    }

    if (!getUser().loggedIn) {
        removeNavigationListener();
        return false;
    }

    let changes = false;
    try {
        changes = hook.checkUnsavedChanges();
    } catch (error) {
        removeNavigationListener();
    }

    return changes;
}

// eslint-disable-next-line consistent-return
function onBeforeUnload(e: BeforeUnloadEvent) {
    if (checkChanges()) {
        e.returnValue = LEAVE_PAGE_MESSAGE;
        return LEAVE_PAGE_MESSAGE;
    }
}

async function shouldNavigate() {
    if (!hook) {
        return true;
    }

    if (!checkChanges()) {
        return true;
    }

    try {
        if (_.isFunction(hook.askUserForAction)) {
            return await new Promise(resolve => {
                hook.askUserForAction(allowNavigation => resolve(allowNavigation));
            });
        }

        return await confirm(LEAVE_PAGE_MESSAGE);
    } catch (error) {
        console.log(error);

        return true;
    }
}

function setNavigationListener({
    checkUnsavedChanges, // must be SYNC
    askUserForAction, // perform own confirm
}: Omit<Hook, 'historyFragment'>) {
    if (!isListenersAdded) {
        window.addEventListener('beforeunload', onBeforeUnload);
        isListenersAdded = true;
    }

    hook = {
        checkUnsavedChanges,

        askUserForAction,

        historyFragment: getHistoryFragment(),
    };
}

export { checkChanges, removeNavigationListener, setNavigationListener, shouldNavigate };
