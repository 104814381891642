import axios from 'axios';
import $ from 'jquery';
import urlParser from 'url-parse';

class StaticSmoothNavigation {
    currentState: Record<string, any> = null;

    isSpinnerShowed: boolean = false;

    history: History;

    location: Location;

    stopped: boolean = false;

    constructor(history, location) {
        this.currentState = null;
        this.isSpinnerShowed = false;
        this.history = history;
        this.location = location;
        this.stopped = false;
    }

    run() {
        this.isSpinnerShowed = false;
        this.currentState = { href: this.makeRootRelativeUrl(this.location.href) };
        this.stopped = false;

        this.history.replaceState(this.currentState, '', this.currentState.href);

        $(window).on('popstate.static-smooth-navigation', e => {
            const state = e.originalEvent.state || { href: this.makeRootRelativeUrl(this.location.href) };
            const prevState = this.currentState;
            this.currentState = state;
            this.handleState(prevState, state);
        });

        $('body').on('click.static-smooth-navigation', 'a[href^="http"], a[href^="/"], a[href^="#"]', e => {
            const roots = [
                'home.html',
                '/author-resources/',
                '/Reviewers/',
                '/editors/',
                '/ethics-guidelines/',
                '/mock_static_images/',
            ];
            const invalidRoots = ['/asset/'];
            const href = $(e.currentTarget).attr('href');
            const parsedHref = urlParser(href, this.location.origin);

            if (
                parsedHref.origin === this.location.origin &&
                roots.some(root => href.indexOf(root) !== -1) &&
                invalidRoots.every(root => href.indexOf(root) === -1)
            ) {
                e.preventDefault();

                const state = { href: this.makeRootRelativeUrl(href) };

                if (this.currentState.href !== state.href) {
                    this.history.pushState(state, '', state.href);
                    const prevState = this.currentState;
                    this.currentState = state;
                    this.handleState(prevState, state);
                }
            }
        });
    }

    stop() {
        this.hideSpinner();
        $(window).off('.static-smooth-navigation');
        $('body').off('.static-smooth-navigation');
        this.currentState = null;
        this.stopped = true;
    }

    async handleState(prevState, state) {
        if (this.isSamePage(prevState.href, state.href)) {
            this.handleAnchors();
            return;
        }

        if (this.isReloadRequiredByDom($('html'))) {
            this.location.reload();
            return;
        }

        this.showSpinner();

        try {
            const response = await axios.get(state.href);

            if (this.stopped || !this.isSamePage(this.currentState.href, state.href)) {
                return;
            }

            if (this.isReloadRequiredByMarkup(response.data)) {
                this.location.reload();
            } else {
                const $dom = $(new DOMParser().parseFromString(response.data, 'text/html'));
                const $container = $dom.find(`body > .container`);

                // dotCMS application/themes/as2/footer.vtl
                if (window._pageIsGoingToBeUpdatedHandler) {
                    window._pageIsGoingToBeUpdatedHandler();
                }

                const keepList = ['.container__crumbtrail', '#AS__top_nav', '.container__sidebar--left'];
                const keepHTML = {};

                const loadedNew = !!$dom.find('head meta[name="as-new-page"][content="true"]').length;

                if (
                    (window.reload_AS_site_navigation && !loadedNew) ||
                    (!window.reload_AS_site_navigation && loadedNew)
                ) {
                    this.location.reload();
                    return;
                }

                if (window.reload_AS_site_navigation) {
                    keepList.forEach(s => {
                        keepHTML[s] = $(s).html();
                    });
                }

                // otherwise loaded js is not working
                $(`body > .container`).html($container.html());
                [
                    'head > title',
                    'head > meta[name="description"]',
                    'head > meta[name="keywords"]',
                    'head > meta[name="author"]',
                    // make analytic handlers work
                    'body > .page__analytics',
                ].forEach(x => $(x).replaceWith($dom.find(x)));

                if (window.reload_AS_site_navigation) {
                    keepList.forEach(s => {
                        const el = $(s);
                        if (el.length && keepHTML[s]) {
                            el.html(keepHTML[s]);
                        }
                    });

                    window.reload_AS_site_navigation();
                }

                this.hideSpinner();
                this.handleAnchors();
            }
        } catch {
            if (this.stopped || !this.isSamePage(this.currentState.href, state.href)) {
                return;
            }

            this.location.reload();
        }
    }

    handleAnchors() {
        // dotCMS application/themes/as2/footer.vtl
        if (window._hideAccordion) {
            window._hideAccordion();
        }
        if (window._handleAccordionHash) {
            window._handleAccordionHash();
        }

        const node = document.getElementById(this.location.hash.replace('#', ''));

        if (node) {
            setTimeout(() => node.scrollIntoView(true), 0);
        }
    }

    hideSpinner() {
        this.isSpinnerShowed = false;
        $('body').removeClass('smooth-navigation-processing');
    }

    showSpinner() {
        this.isSpinnerShowed = true;
        setTimeout(() => this.isSpinnerShowed && $('body').addClass('smooth-navigation-processing'), 100);
    }

    makeRootRelativeUrl(url) {
        const parsed = urlParser(url, this.location.origin);

        return parsed.href.replace(parsed.origin, '');
    }

    isReloadRequiredByMarkup(markup) {
        // hack for tests. PhantomJS does not fully support DOMParser.
        return markup.indexOf('<meta name="static-page-smooth-navigation-allowed" content="true">') === -1;
    }

    isReloadRequiredByDom($dom) {
        return $dom.find('head meta[name="static-page-smooth-navigation-allowed"][content="true"]').length === 0;
    }

    isSamePage(href1, href2) {
        return href1.split('#')[0] === href2.split('#')[0];
    }
}

export default StaticSmoothNavigation;
