import get from 'lodash.get';
import React from 'react';
import ReactDOM from 'react-dom';
import { watchInfo } from 'app/blocks/common/as-info';
import { lAsync } from 'app/blocks/common/codes';
import PageEnum from 'app/blocks/common/PageEnum';
import { getUser } from 'app/blocks/middleware/authentication';
import { isNonProd } from 'app/blocks/middleware/config';
import { getUrlParam } from 'app/blocks/middleware/url-params';
import ToggleSupportWidgetButton from './buttons/ToggleSupportWidgetButton';
import './SupportWidget.scss';

let firstRun = true;
let preconfiguredExtraPrechatFormDetails = [];

/* todo:
            label: 'Institution / University',
            transcriptFields: ['Institution_University__c'],
            value: 'Institution name',
        },
        {
 */

const getParticipant = (article, user) => {
    const { email } = user;
    return (article.authors || []).find(author => author.email === email);
};

const getJourney = (article, other, user) => {
    let data: Record<string, string> = {
        Email: user?.email,
        'LogRocket Session ID': window.LogRocket ? window.LogRocket.recordingID : null,
        'Email Campaign ID': getUrlParam('campaign'),
    };

    if (other) {
        for (const key of ['fullWOAAOffer', 'requiredActionsList', 'requiredActions']) {
            if (other[key] !== undefined) {
                data[key] = other[key];
            }
        }
    }

    if (article) {
        const participant = getParticipant(article, user);

        data = {
            ...data,

            // @ts-ignore
            'Article ID': article.id,

            // @ts-ignore
            'Participant Primary Role': participant?.role?.primaryRole,
            // @ts-ignore
            'Participant Additional Roles': participant?.role?.additionalRoles?.join(', '),
            // @ts-ignore
            'Publication Status': article.pubStatus,
        };
    }

    const journey = [];

    for (const k in data) {
        journey.push(`${k}: ${data[k]}`);
    }

    return journey;
};

function setExtraPrechatFormDetails(info, user) {
    if (!window.embedded_svc?.settings?.extraPrechatFormDetails || !info) return;

    if (firstRun) {
        preconfiguredExtraPrechatFormDetails = window.embedded_svc.settings.extraPrechatFormDetails;
        firstRun = false;
    }

    const { article, journal, other, page } = info;
    if (!article || !journal || !user) {
        return;
    }

    const journey = getJourney(article, other, user);

    window.embedded_svc.settings.extraPrechatFormDetails = preconfiguredExtraPrechatFormDetails.concat([
        {
            label: 'External User ID', // alm id
            transcriptFields: ['External_User_ID__c'],
            value: get(user, 'userId'),
        },
        {
            label: 'Book / Journal Title',
            transcriptFields: ['Book_Journal_Title__c'],
            value: get(journal, 'name'),
        },
        {
            label: 'Journal Group Code',
            transcriptFields: ['Journal_Acronym__c'],
            value: get(journal, 'groupCode') || get(journal, 'abbreviation'),
        },
        {
            label: 'Article Title',
            transcriptFields: ['Article_Title__c'],
            value: get(article, 'name'),
        },
        {
            label: 'Article DOI',
            transcriptFields: ['Article_DOI__c'],
            value: get(article, 'doi'),
        },
        {
            label: 'Page Type',
            transcriptFields: ['Page_Type__c'],
            value: page,
        },
        {
            label: 'Journey',
            transcriptFields: ['Journey__c'],
            value: journey.join('; '),
        },
    ]);
}

function prepopulatePrechatFields(user) {
    if (!window.embedded_svc?.settings?.prepopulatedPrechatFields) return;

    window.embedded_svc.settings.prepopulatedPrechatFields.FirstName = get(user, 'givenName');
    window.embedded_svc.settings.prepopulatedPrechatFields.LastName = get(user, 'familyName');
    window.embedded_svc.settings.prepopulatedPrechatFields.Email = get(user, 'primaryEmail');
}

// todo: move to utils
function loadStylesheet(href: string) {
    const link = document.createElement('link');
    link.setAttribute('href', href);
    link.setAttribute('rel', 'stylesheet');
    document.head.appendChild(link);
}

// todo: move to utils
function loadScript(src: string) {
    const script = document.createElement('script');
    script.src = src;
    script.async = false;
    document.body.append(script);
}

const SUPPORT_WIDGET_CONTAINER_ID = 'AS__supportWidget';
const FOOTER_CONTAINER_ID = 'AS__footer';

export default async function injectCRM() {
    const config: Record<string, string> = (await lAsync(
        `SUPPORT_WIDGET.CONFIG.${isNonProd() ? 'NONPROD' : 'PROD'}`,
    )) as unknown as Record<string, string>;

    if (config.BASE_CSS) loadStylesheet(config.BASE_CSS);
    if (config.AS_CSS) loadStylesheet(config.AS_CSS);
    if (config.ESW_JS) loadScript(config.ESW_JS);
    if (config.AS_JS) loadScript(config.AS_JS);

    const container = document.createElement('div');
    container.id = SUPPORT_WIDGET_CONTAINER_ID;
    container.style.display = 'none';

    const footerNode = document.getElementById(FOOTER_CONTAINER_ID);
    footerNode.parentNode.insertBefore(container, footerNode);

    function handleHelpClick(e) {
        const helpOriginal: HTMLButtonElement = document.querySelector('.embeddedServiceHelpButton .helpButton button');
        const helpMinimized: HTMLButtonElement = document.querySelector('.embeddedServiceSidebar button.helpButton');

        (helpMinimized || helpOriginal)?.click();

        const ourButton = e?.currentTarget;
        if (ourButton) {
            ourButton.style.opacity = '0.3';
            setTimeout(() => {
                ourButton.style.opacity = '';
            }, 10000);
        }
    }

    ReactDOM.render(
        <div className="SupportWidget">
            <ToggleSupportWidgetButton onClick={e => handleHelpClick(e)} />
        </div>,
        container,
    );

    async function onPageChange(e) {
        container.style.display =
            e === null ||
            e?.path?.replace('/', '')?.toLowerCase() === PageEnum.HOME.toLowerCase() ||
            !window.embedded_svc
                ? 'none'
                : '';

        if (!window.embedded_svc) return;

        const user = getUser();
        setExtraPrechatFormDetails(e, user);
        prepopulatePrechatFields(user);

        // console.log(window.embedded_svc.settings);
    }

    let attemptsLeft = 100;
    const waitForLoading = setInterval(() => {
        attemptsLeft -= 1;

        if (window.embedded_svc || !attemptsLeft) {
            clearInterval(waitForLoading);
            watchInfo(onPageChange);
        }
    }, 500);
}
