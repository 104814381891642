import React from 'react';
import emitter from 'app/blocks/common/events/emitter';
import Events from 'app/blocks/common/events/Events';
import * as api from 'app/blocks/middleware/authentication';

export type AuthContextInterface = {
    user: {
        chinaPIPLConsent?: boolean;
        countryCode?: string;
        countryNameNE?: string;
        isAdminMode?: boolean;
        loggedIn: boolean;
        primaryEmail?: string;
        sendEmailFlag?: boolean;
        wileyTermsOfUseConsent?: boolean;
    };
    isAuth: boolean;
};

export const AuthContext = React.createContext<AuthContextInterface>({ user: { loggedIn: false }, isAuth: false });

export const useAuth = () => React.useContext(AuthContext);

export default function AuthProvider({ children }: { children: React.ReactNode }): React.ReactElement {
    const [user, setUser] = React.useState(api.getUser());

    React.useEffect(() => {
        return emitter.subscribe(Events.USER_CHANGED, setUser);
    }, []);

    return <AuthContext.Provider value={{ isAuth: user.loggedIn, user }}>{children}</AuthContext.Provider>;
}
